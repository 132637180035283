const Auth = {
isAuthenticated: false,
authenticate() {
localStorage.setItem('auth', true);
},
signout() {
localStorage.setItem('auth', false);
},
getAuth() {

let val=localStorage.getItem('auth');
console.log("vaaaaaa",val)
if(val!=null)
{
   if(val=="true")
   {
        return true
   }
   else{
         return false
   }
  
}
else
{
    return false
}





}
};
export default Auth;