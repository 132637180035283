/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_de from "./translations/it.json";
import common_en from "./translations/en.json";


// core components
import Login from "views/Login/Login.js";
 import ADMIN from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import Auth from './Auth';
import "assets/css/material-dashboard-react.css?v=1.9.0";

const hist = createBrowserHistory();

i18next.init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: 'en',                              // language to use
        resources: {
            en: {
                common: common_en               // 'common' is our custom namespace
            },
            de: {
                common: common_de
            },
        },
    });


const PrivateRoute = ({ component: Component, ...rest }) => (
<Route
{...rest}
render={props =>
Auth.getAuth() ? (
<Component {...props} />
) : (
<Redirect
to={{
pathname: "/login"
}}
/>
)
}
/>
);

ReactDOM.render(
  <Router history={hist}>
    <Switch>
     <I18nextProvider i18n={i18next}>
     <Route path="/login" component={Login} />
      <PrivateRoute path="/admin" component={ADMIN} />
      
      <Redirect from="/" to="/login" />
      </I18nextProvider>
    </Switch>
  </Router>,
  document.getElementById("root")
);
