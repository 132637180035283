
//import all the components we are going to use. 
//import localStorageService from "./localStorageService";
const axios = require('axios');

class ApiUrl {


//   this.API_URL="http://192.168.1.2:3333/api";
  async getDataUsingGet(subUrl)
  {
      //drive.dumpin.in
      const token=localStorage.getItem("jwt_token", token);
      console.log(token)
        const head= {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
      console.log(subUrl)
        try {
            const response = await axios.get("https://drive.dumpin.in/api"+subUrl,head);
             return {'log':true,'response':response.data}; 
        } catch (error) {
            console.log(error);
            return {'log':false,'response':error}; 
        }
  }

 async  getDataUsingPost(subUrl,params)
  {
        console.log(subUrl,params)
        try {
            const response = await axios.post("https://drive.dumpin.in/api"+subUrl,params);
             return {'log':true,'response':response.data}; 
        } catch (error) {
            console.log(error);
            return {'log':false,'response':error}; 
        }
            
          
  }
 
}
const Api = new ApiUrl();

export default Api;