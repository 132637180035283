import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Redirect } from 'react-router-dom';
import Auth from '../../Auth';
import Api from 'variables/Api';

import { API_URL } from "variables/Api_url.js";
import localStorageService from "variables/localStorageService";


import bgImage from "assets/img/sidebar-4.jpg";
import logo from "assets/img/logo.png";



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://Ovgile.com/">
        Ovgile.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,

  },
  avatar: {
    margin: theme.spacing(1),

    width: 300,

  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

//  const useStyles = useStyles();
class Login extends React.Component {


  constructor(props) {
    super();
    this.state = {
      email: '',
      password: '',
      isAuth: false,
    };

    this.validateForm = this.validateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleSubmit = async () => {



    let reqdata = { "username": this.state.email, "password": this.state.password }
    let responsedata = await Api.getDataUsingPost(API_URL.login, reqdata);
    console.log(responsedata)
    if (responsedata.log) {
      if (responsedata.response.status) {
        localStorage.setItem("jwt_token", responsedata.response.user.token);
        Auth.authenticate();
        this.props.history.push('/admin');
      }
      else {
        alert("Invalid Credential !!!!")
      }

    }

  }


  componentDidMount() {


    if (Auth.getAuth()) {
      this.props.history.push('/admin');
    }
    else {
      Auth.signout();
    }

  }



  render() {
    const { classes } = this.props;


    return (
      <Container component="main" maxWidth="xs" >
        <CssBaseline />
        <div className={classes.paper}>

          {/* <LockOutlinedIcon /> */}
          <img src={logo} alt="logo" className={classes.avatar} />


          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={this.state.email}
              onChange={e => this.setState({ 'email': e.target.value })}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={this.state.password}
              onChange={e => this.setState({ 'password': e.target.value })}
            />

            <Button

              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!this.validateForm()}
              onClick={() => this.handleSubmit()}
            >
              Sign In
          </Button>

          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}
// export default  withTranslation('common')(login);
export default withStyles(useStyles, { withTheme: true })(Login);