import React from "react";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// import Box from '@material-ui/core/Box';
// import Collapse from '@material-ui/core/Collapse';
// import IconButton from '@material-ui/core/IconButton';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardAvatar from "components/Card/CardAvatar.js";
 import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Api from 'variables/Api';

import {API_URL} from "variables/Api_url.js";

import TableSortLabel from '@material-ui/core/TableSortLabel';
import MaterialTable from 'material-table';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};
















class DriverList extends React.Component {
 


  
 constructor(props) {
    super();
    this.state ={
                rows:[],
                selectedRow:null,
                update:false,
                driver:null,
                open:false

                };
   
     this.getDataUpdate = this.getDataUpdate.bind(this);
     this.assignDriver = this.assignDriver.bind(this);
     this.getDriver = this.getDriver.bind(this);
  }


  componentDidMount() {

             this.getDataUpdate();
            
  }

  async getDataUpdate(){
              let responsedata=await Api.getDataUsingGet(API_URL.getDrivers);
              console.log(responsedata)
              if(responsedata.log)
              {
                console.log(responsedata.response.drivers)
                 this.setState({
                   rows:responsedata.response.drivers,
                   update:false,
                 })
              }
   }




  
 
  getDriver(value){
            this.setState({
                    open:true,
                    driver:value
                  })

  }



  async assignDriver(value){
   
          let params={'orderid':this.props.props.orderid,'driverid':value.id,'status':2}
          let responsedata=await Api.getDataUsingPost(API_URL.assignOrder,params);
          console.log(responsedata)
              if(responsedata.log)
              {
                    if(responsedata.response.status)
                    {
                    alert("Assign Successfully")
                    
                    }
                    this.setState({
                    open:false,
                    driver:null,
                   }) 
                    this.props.props.fun();
                  
                   
              }
           
   }
  
  
  render(){
    console.log(this.props)
  return (
    <>
    <GridContainer>
      <GridItem xs={10} sm={10} md={12}>
        <Card>
          
          <CardBody>
 
      
   <MaterialTable
   style={{backgroundColor:'#EEEEEE',color: 'black'}}
      title="Drivers List"
      columns={[
        // { title: 'Username', field: 'username' },
        { title: 'First Name', field: 'first_name' },
        { title: 'Last Name', field: 'last_name' },
        { title: 'Contact No', field: 'mobileno', type: 'numeric' },
        { title: 'Email Id', field: 'emailid' },
        
        
      ]}
      data={this.state.rows}        
      actions={[
        {
          icon: 'assignment_ind',
          tooltip: 'Assign Driver',
          onClick: (event, rowData) => this.getDriver(rowData)
        },
       
      ]}
      onRowClick={((evt, selectedRow) => this.setState({'selectedRow':selectedRow.tableData.id}))}
     
       
      //  detailPanel={[
      //   {
      //     tooltip: 'Show Name',
      //     render: rowData => {
      //       return (
      //         <div
      //           style={{
      //             fontSize: 100,
      //             textAlign: 'center',
      //             color: 'white',
      //             backgroundColor: '#43A047',
      //           }}
      //         >
      //           {rowData.mobileno}
      //         </div>
      //       )
      //     },
      //   },
      // ]}
       options={{
        actionsColumnIndex: -1,
         headerStyle: {
          backgroundColor: '#EEEEEE',
          color: 'black'
        },
         rowStyle: rowData => rowData.active
              ? { background: "#3DFFB8" }
              : { background: "#F5B7B1" }
              
      }}
      
    />
     
          </CardBody>
        </Card>
      </GridItem>
  
  
  
  
  
  
  
    </GridContainer>
  {this.state.driver!==null?
      <Dialog
        open={this.state.open}
        
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Action  "}</DialogTitle>
        <DialogContent>
       
        <>
         <DialogContentText id="alert-dialog-slide-description">
         
          Are you sure to Assign job to this Driver
        
          </DialogContentText>


          <DialogContentText id="alert-dialog-slide-description">
          
           Driver Name --   {this.state.driver.first_name+" "+this.state.driver.first_name}
          </DialogContentText>
          
         
          </>
         
        </DialogContent>
        <DialogActions>
         
          <Button onClick={()=>this.assignDriver(this.state.driver)} color="primary">
            ok
          </Button>
        </DialogActions>
      </Dialog>
     :null}

    </>

  );
  }
}



export default DriverList;