import React from "react";
// @material-ui/core components
import { withStyles, makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import DriverList from "../AddDriver/DriverList.js";
import avatar from "assets/img/faces/marc.jpg";
import { compose, withProps } from "recompose";
import Icon from "@material-ui/core/Icon";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ReactLoading from "react-loading";

import Api from 'variables/Api';
import { withTranslation } from 'react-i18next';
import { API_URL, ORDER_STATUS, PHOTO } from "variables/Api_url.js";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import MaterialTable from 'material-table';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};















const MyMapComponent = compose(

  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDy4YDcrHfTyCRV_IVjlBj8TvIkNLK3hVo&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `50%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (

  <GoogleMap defaultZoom={17} center={{ lat: props.props.lat, lng: props.props.long }}>
    <Marker position={{ lat: props.props.lat, lng: props.props.long }} onClick={() => alert(JSON.stringify(props))} />
  </GoogleMap>
));



class CompleteOrder extends React.Component {

  constructor(props) {
    super();
    this.state = {
      rows: [],
      order: null,
      selectedRow: null,
      update: false,
      shipping: null,
      item: [],
      open: false
    };

    this.getOrderList = this.getOrderList.bind(this);
    this.showOrderDetail = this.showOrderDetail.bind(this);
  }



  componentDidMount() {

    this.getOrderList();

  }

  async getOrderList() {
    this.setState({ open: true })
    let responsedata = await Api.getDataUsingGet(API_URL.completedOrder);
    console.log(responsedata)
    if (responsedata.log) {
      console.log(responsedata.response)
      this.setState({
        rows: responsedata.response.order,
        open: false

      })
    }
  }





  showOrderDetail(value) {
    console.log(value)
    this.setState({
      shipping: value.Shipments,
      item: value.Items
    })

  }




  render() {
    const { t } = this.props;

    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4 style={styles.cardTitleWhite}>{t('completeOrders')} </h4>
                {/* <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p> */}
                <Icon style={{ position: 'absolute', right: 20, top: 8, fontSize: 30, backgroundColor: 'white', color: 'gray', borderRadius: 20, padding: 5 }} onClick={() => this.getOrderList()}>refresh</Icon>
              </CardHeader>
              <CardBody>


                <MaterialTable
                  title=""
                  style={{ color: 'black' }}
                  columns={[
                    { title: t('orderId'), field: 'id' },
                    { title: t('orderDate'), field: 'created_at' },
                    { title: t('totalPrice'), field: 'total_price' },

                  ]}
                  data={this.state.rows}
                  actions={[


                    {
                      icon: 'remove_red_eye',
                      tooltip: 'Show Detail',
                      onClick: (event, rowData) => this.showOrderDetail(rowData)
                    }
                  ]}
                  onRowClick={((evt, selectedRow) => this.setState({ 'selectedRow': selectedRow.tableData.id }))}



                  detailPanel={[
                    {
                      tooltip: 'Assign Driver',
                      render: rowData => {

                        return (
                          <GridContainer>

                            <GridItem xs={6} sm={6} md={4}>

                              <img src={rowData.payements != null ? PHOTO.url + rowData.payements.document : ''} style={{ width: 200, height: 180 }} />


                            </GridItem>
                            <GridItem xs={8} sm={8} md={8}>

                              <div style={{ fontSize: 20, padding: 10, color: 'black' }}>Driver Name ----   {rowData.drivers.first_name + " " + rowData.drivers.last_name}</div>
                              <div style={{ fontSize: 15, padding: 10, color: 'black' }}>Contact ----   {rowData.drivers.mobileno}</div>
                              <div style={{ fontSize: 15, padding: 10, color: 'black' }}>Email ----   {rowData.drivers.emailid}</div>
                              <div style={{ fontSize: 15, padding: 10, color: 'black' }}>Completed date-----{rowData.payements != null ? rowData.payements.updated_at : ''}</div>


                            </GridItem>

                          </GridContainer>
                        )

                      },
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    rowStyle: { background: "#F5B7B1" }
                  }}
                />

              </CardBody>
            </Card>
          </GridItem>






          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardHeader color="primary">
                <p style={styles.cardTitleWhite}>{t('orderDetail')} </p>

              </CardHeader>

              <CardBody profile>
                {this.state.shipping !== null ?
                  <MyMapComponent props={{ 'lat': this.state.shipping.latitude, 'long': this.state.shipping.longitude }} />
                  :
                  <MyMapComponent props={{ 'lat': -34.397, 'long': 150.644 }} />
                }
                <h6 style={{ color: '#9c27b0', fontWeight: 600 }}>{t('shippingDetail')} </h6>
                {this.state.shipping !== null ?
                  <>
                    <p style={{ color: 'black' }}>{t('customerName')} - {this.state.shipping.first_name + " " + this.state.shipping.last_name} </p>
                    <p style={{ color: 'black' }}>{t('contactNumber')} - {this.state.shipping.phone} </p>

                    <p style={{ color: 'black' }}>{t('address')} -{this.state.shipping.address1 + " " + this.state.shipping.address2} </p>
                  </>
                  : null}
                <MaterialTable
                  title=""
                  style={{ color: 'black' }}
                  columns={[
                    { title: t('name'), field: 'name' },
                    { title: t('quantity'), field: 'quantity' },


                  ]}
                  data={this.state.item}



                  detailPanel={[
                    {
                      tooltip: 'Show Price',
                      render: rowData => {
                        return (
                          <div
                            style={{
                              fontSize: 15,
                              marginLeft: 50,

                            }}
                          >
                            {rowData.price}
                          </div>
                        )
                      },
                    },
                  ]}

                />
                {/* <Button color="primary" round>
                Follow
              </Button> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>


        <Dialog
          open={this.state.open}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">

          <DialogContent>
            <ReactLoading type={"balls"} color="balck" />
          </DialogContent>

        </Dialog>
      </>
    );
  }
}

export default withTranslation('common')(CompleteOrder);