// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var API_URL={
   


    login:'/admin/login',
    getOrderss:'/order/orders',
    newOrders:'/order/newOrders',
    asigngedOrder:'/order/asignOrder',
    completedOrder:'/order/completedOrder',
    driverLocation:'/order/driverLocation',



    assignOrder:'/order/orderAssign',
    
    getDrivers:'/driver/drivers',
    addDrivers:'/driver/addDriver',
    updateDriver:'/driver/updateDriver'
     
    
    
    }

var ORDER_STATUS={
    fresh:1,
    assign:2,
    processing:3,
    finish:4
    
}    

var DRIVER_STATUS={
   
    assign:1,
    accept:2,
    reject:3,
    completed:4,
    
} 
var PHOTO={
   
     url:'https://drive.dumpin.in/api/file/download/'
     //url:'http://192.168.43.173:3333/api/file/download/'
}   



module.exports = {
 
 API_URL,
 ORDER_STATUS,
 PHOTO
};