import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow 
} from "react-google-maps";
import { compose, withProps,withStateHandlers } from "recompose";
import Api from 'variables/Api';
import { withTranslation } from 'react-i18next';
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import {API_URL} from "variables/Api_url.js";

// const CustomSkinMap = withScriptjs(
//   withGoogleMap(() => (
//     <GoogleMap
//       defaultZoom={13}
//       defaultCenter={{ lat:8.974322, lng: -79.529326 }}
//       // defaultOptions={{
//       //   scrollwheel: false,
//       //   zoomControl: true,
//       //   styles: [
//       //     {
//       //       featureType: "water",
//       //       stylers: [
//       //         { saturation: 43 },
//       //         { lightness: -11 },
//       //         { hue: "#0088ff" }
//       //       ]
//       //     },
//       //     {
//       //       featureType: "road",
//       //       elementType: "geometry.fill",
//       //       stylers: [
//       //         { hue: "#ff0000" },
//       //         { saturation: -100 },
//       //         { lightness: 99 }
//       //       ]
//       //     },
//       //     {
//       //       featureType: "road",
//       //       elementType: "geometry.stroke",
//       //       stylers: [{ color: "#808080" }, { lightness: 54 }]
//       //     },
//       //     {
//       //       featureType: "landscape.man_made",
//       //       elementType: "geometry.fill",
//       //       stylers: [{ color: "#ece2d9" }]
//       //     },
//       //     {
//       //       featureType: "poi.park",
//       //       elementType: "geometry.fill",
//       //       stylers: [{ color: "#ccdca1" }]
//       //     },
//       //     {
//       //       featureType: "road",
//       //       elementType: "labels.text.fill",
//       //       stylers: [{ color: "#767676" }]
//       //     },
//       //     {
//       //       featureType: "road",
//       //       elementType: "labels.text.stroke",
//       //       stylers: [{ color: "#ffffff" }]
//       //     },
//       //     { featureType: "poi", stylers: [{ visibility: "off" }] },
//       //     {
//       //       featureType: "landscape.natural",
//       //       elementType: "geometry.fill",
//       //       stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
//       //     },
//       //     { featureType: "poi.park", stylers: [{ visibility: "on" }] },
//       //     {
//       //       featureType: "poi.sports_complex",
//       //       stylers: [{ visibility: "on" }]
//       //     },
//       //     { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
//       //     {
//       //       featureType: "poi.business",
//       //       stylers: [{ visibility: "simplified" }]
//       //     }
//       //   ]
//       // }}
//     >
//       <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
//     </GoogleMap>
//   ))
// );

const CustomSkinMap = compose(
 
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDy4YDcrHfTyCRV_IVjlBj8TvIkNLK3hVo&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `90%` }} />
  }),
   withStateHandlers(() => ({
    isOpen: false,
  }), {
    onToggleOpen: ({ isOpen }) => () => ({
      isOpen: !isOpen,
    })
  }),
  withScriptjs,
  withGoogleMap
)(props => (

  <GoogleMap defaultZoom={13} defaultCenter={{ lat:8.974322, lng: -79.529326 }}>
  


  
    {props.props.listo.map((item)=>  

              item.locations!=null?<Marker key={item.id} position={{ lat: item.locations.latitude, lng:  item.locations.longitude }}    onClick={props.onToggleOpen}>
                {props.isOpen && <InfoBox
                  onCloseClick={props.onToggleOpen}
                  options={{ closeBoxURL: ``, enableEventPropagation: true }}
                >
                <div style={{ backgroundColor: '#4a27d4' ,paddingLeft:'15px',borderRadius:'10px'}}>
                  <div style={{ fontSize: '15px', color: 'white' }}>
                  {item.first_name +" "+item.last_name}
                  </div>
                </div>
              </InfoBox>}
              </Marker>
              :null
       )}
   


  </GoogleMap>
));


class Maps extends React.Component {



  
  
 constructor(props) {
    super();
    this.state ={
                rows:[],
               activeMarker: {},
              selectedPlace: {},
              showingInfoWindow: false
                // selectedRow:null,
                // update:false,
                // driver:null,
                // open:false

                };
   
    //  this.getDataUpdate = this.getDataUpdate.bind(this);
    //  this.actionDriver = this.actionDriver.bind(this);
    //  this.getDriver = this.getDriver.bind(this);
  }


  componentDidMount() {

             this.getDriverLocations();
            
  }

   getDriverLocations=async()=>{
              let responsedata=await Api.getDataUsingGet(API_URL.driverLocation);
             // console.log(responsedata)
              if(responsedata.log)
              {
                console.log(responsedata.response)
                 this.setState({
                   rows:responsedata.response.drivers,
                   update:false,
                 })
              }
   }


    onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

  render(){

    const {rows,center}=this.state
    
 
  return (
    <>
    <CustomSkinMap props={{'listo':rows,'actiononclicc':this.onMarkerClick}}>
     
      </CustomSkinMap>

        

</>
  );
}
}



export default  withTranslation('common')(Maps);