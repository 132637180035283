import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
//import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
//import CardFooter from "components/Card/CardFooter.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
//import avatar from "assets/img/faces/marc.jpg";
import Api from 'variables/Api';
import { withTranslation } from 'react-i18next';
import {API_URL} from "variables/Api_url.js";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { compose, withProps } from "recompose";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const useStyles = makeStyles(styles);
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};



const MyMapComponent = compose(
  
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDy4YDcrHfTyCRV_IVjlBj8TvIkNLK3hVo&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `50%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  
  <GoogleMap defaultZoom={8} center={{ lat:props.props.lat, lng: props.props.long }}>
    <Marker position={{ lat:props.props.lat, lng: props.props.long}} onClick={()=>alert(JSON.stringify(props))}/>
  </GoogleMap>
));


class ShowDriver extends React.Component {

   constructor(props) {
    super();
    this.state =props.props.driver
   
    this.onInputchange = this.onInputchange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    }



   handleClose = () => {
    this.setState({
      open:false,
    })
    };

componentWillReceiveProps(nextProps) {
  console.log("new",nextProps.props.driver.id)
  console.log("prev",this.state.id)
  // You don't have to do this check first, but it can help prevent an unneeded render
  if (nextProps.props.driver.id !== this.state.id) {
    console.log("jhgjgjgjgjg")
    this.setState(nextProps.props.driver);
  }
}

    onInputchange(event) {
      
                    this.setState({
                    [event.target.id]: event.target.value
                    });
    }

    async onSubmitForm() {

           
            if(this.state.first_name.length<1)
            {
                this.setState({
                  open:true,
                  msg:"Please enter First Name !!!"
                })
               
                return;
            }
            if(this.state.last_name.length<1)
            {

               
                this.setState({
                  open:true,
                  msg:"Please enter Last Name !!!"
                })
                return;
            }
             if(this.state.emailid.length<1)
            {

               
                this.setState({
                  open:true,
                  msg:"Please enter Email id !!!"
                })
                return;
            }
            
            
            if(this.state.address.length<1)
            {

             
                this.setState({
                  open:true,
                  msg:"Please enter Address !!!"
                })
                return;
            }
             if(this.state.city.length<1)
            {

               
                this.setState({
                  open:true,
                  msg:"Please enter City !!!"
                })
                return;
            }
             if(this.state.country.length<1)
            {

               this.setState({
                  open:true,
                  msg:"Please enter Country !!!"
                })
                return;
            }
             if(this.state.pincode.length<1)
            {

                
               this.setState({
                  open:true,
                  msg:"Please enter PinCode !!!"
                })
                return;
            }








            let responsedata=await Api.getDataUsingPost(API_URL.updateDriver,this.state);
              if(responsedata.log)
              {
                    alert("Successfully Update Driver")
                    console.log(responsedata.response.data)
                    this.props.props.fun();
              }
           
   }


render(){
    const { t } = this.props;
   
  return (
    <div>
    
          <Card profile>
            <CardHeader color="primary">
              <h4 style={styles.cardTitleWhite}>{t('driverDetail')}</h4>
              <p style={styles.cardCategoryWhite}>{t('pleaseFill')}</p>
            </CardHeader>
            <CardBody>
             {this.state.locations!==null?
             <MyMapComponent props={{'lat':this.state.locations.latitude,'long':this.state.locations.longitude}} />
             :
            null
              }
                 <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={t('firstName')}
                    id="first_name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value:this.state.first_name,
                      onChange:this.onInputchange
                    
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={t('lastname')}
                    id="last_name"
                    formControlProps={{
                      fullWidth: true
                    }}
                     inputProps={{
                        value:this.state.last_name,
                      onChange:this.onInputchange
                    
                    }}
                  />
                </GridItem>
              </GridContainer>
               <GridContainer>
               <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={t('contactno')}
                    id="mobileno"
                    formControlProps={{
                      fullWidth: true
                    }}
                     inputProps={{
                        value:this.state.mobileno,
                      onChange:this.onInputchange
                    
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={t('username')}
                    id="username"
                    formControlProps={{
                      fullWidth: true
                    }}
                     inputProps={{
                       disabled: true,
                      value:this.state.username,
                      onChange:this.onInputchange
                    
                    }}
                  />
                </GridItem>
              
                
              </GridContainer>
                 <GridContainer>
               <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={t('email')}
                    id="emailid"
                    formControlProps={{
                      fullWidth: true
                    }}
                     inputProps={{
                        value:this.state.emailid,
                      onChange:this.onInputchange
                    
                    }}
                  />
                </GridItem>
                </GridContainer>
              
                <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={t('address')}
                    id="address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 3
                    }}
                     inputProps={{
                       value:this.state.address,
                      onChange:this.onInputchange
                    
                    }}
                  />
                </GridItem>
                
                
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={t('city')}
                    id="city"
                    formControlProps={{
                      fullWidth: true
                    }}
                     inputProps={{
                        value:this.state.city,
                      onChange:this.onInputchange
                    
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={t('country')}
                    id="country"
                    formControlProps={{
                      fullWidth: true
                    }}
                     inputProps={{
                        value:this.state.country,
                      onChange:this.onInputchange
                    
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={t('pincode')}
                    id="pincode"
                    formControlProps={{
                      
                      fullWidth: true
                    }}
                     inputProps={{
                        value:this.state.pincode,
                      onChange:this.onInputchange
                    
                    }}
                  />
                </GridItem>
              </GridContainer>
                            <Button color="primary"  style={{marginTop:50}}  onClick={this.onSubmitForm}>{t('updatePro')}</Button>
            </CardBody>
           
          </Card>
      <Dialog
        open={this.state.open}
        
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Alert !!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           {this.state.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
         
          <Button onClick={this.handleClose} color="primary">
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
}




export default  withTranslation('common')(ShowDriver);