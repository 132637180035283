import React from "react";
// @material-ui/core components
import {  withStyles,makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AddDriver from "../AddDriver/AddDriver.js";
import ShowDriver from "../AddDriver/ShowDriver.js";
import avatar from "assets/img/faces/marc.jpg";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { compose, withProps } from "recompose";
import Api from 'variables/Api';
import ReactLoading from "react-loading";
import { withTranslation } from 'react-i18next';

import {API_URL} from "variables/Api_url.js";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import MaterialTable from 'material-table';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};
















class TableList extends React.Component {
 


  
 constructor(props) {
    super();
    this.state ={
                rows:[],
                selectedRow:null,
                update:false,
                driver:null,
                open:false,
                dialogue:true

                };
   
     this.getDataUpdate = this.getDataUpdate.bind(this);
     this.actionDriver = this.actionDriver.bind(this);
     this.getDriver = this.getDriver.bind(this);
  }


  componentDidMount() {

             this.getDataUpdate();
            
  }

  async getDataUpdate(){
              let responsedata=await Api.getDataUsingGet(API_URL.getDrivers);
              if(responsedata.log)
              {
                console.log(responsedata.response)
                 this.setState({
                   rows:responsedata.response.drivers,
                   update:false,
                   dialogue:false
                 })
              }
   }




  
  
  showDriver(value){
                  this.setState({
                    update:true,
                    driver:value
                  })
                }
   
  getDriver(value){
     this.setState({
                    open:true,
                    driver:value
                  })

  }



  async actionDriver(value){
   
          value.active=!value.active
           delete value['locations']
          let responsedata=await Api.getDataUsingPost(API_URL.updateDriver,value);
              if(responsedata.log)
              {
                    this.getDataUpdate();
                    this.setState({
                    open:false,
                    driver:null,
                   
            }) 
                   
              }
           
   }
  
  
  render(){
     const { t } = this.props;
  return (
    <>
    <GridContainer>
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="primary">
            <h4 style={styles.cardTitleWhite}>{t('driverList')}</h4>
            {/* <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p> */}
          </CardHeader>
          <CardBody>
 
      
   <MaterialTable
      title=""
      columns={[
        // { title: 'Username', field: 'username' },
        { title: t('firstName'), field: 'first_name' },
        { title: t('lastName'), field: 'last_name' },
        { title: t('contactNo'), field: 'mobileno', type: 'numeric' },
        { title: t('emailId'), field: 'emailid' },
        
        
      ]}
      data={this.state.rows}        
      actions={[
        {
          icon: 'assignment_ind',
          tooltip: 'Assign Driver',
          onClick: (event, rowData) => this.getDriver(rowData)
        },
        {
          icon: 'remove_red_eye',
          tooltip: 'Show Detail',
          onClick: (event, rowData) => this.showDriver(rowData)
        }
      ]}
      onRowClick={((evt, selectedRow) => this.setState({'selectedRow':selectedRow.tableData.id}))}
     
       
       detailPanel={[
        {
          tooltip: 'Show Name',
          render: rowData => {
            return (
              <div
                style={{
                  fontSize: 100,
                  textAlign: 'center',
                  color: 'white',
                  backgroundColor: '#43A047',
                }}
              >
                {rowData.mobileno}
              </div>
            )
          },
        },
      ]}
       options={{
        actionsColumnIndex: -1,
         rowStyle: rowData => rowData.active
              ? { background: "#3DFFB8" }
              : { background: "#F5B7B1" }
      }}
      
    />
     
          </CardBody>
        </Card>
      </GridItem>
  
  
  
  
  
  
  <GridItem xs={12} sm={12} md={4}>
      {this.state.driver!==null?<ShowDriver  props={{'driver':this.state.driver,'fun':this.getDataUpdate}} />:<AddDriver props={{'fun':this.getDataUpdate}} />}
          
        </GridItem>
    </GridContainer>
  {this.state.driver!==null?
      <Dialog
        open={this.state.open}
        
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Action  "}</DialogTitle>
        <DialogContent>
       
        <>
         <DialogContentText id="alert-dialog-slide-description">
          {this.state.driver.active?
          "Are you sure to Deactivate Driver"
          :"Are you sure to Activate Driver"
          }
          </DialogContentText>


          <DialogContentText id="alert-dialog-slide-description">
          
           Driver Name --   {this.state.driver.first_name+" "+this.state.driver.first_name}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
          
           Driver Username ----{this.state.driver.username}
          </DialogContentText>
         
          </>
         
        </DialogContent>
        <DialogActions>
         
          <Button onClick={()=>this.actionDriver(this.state.driver)} color="primary">
            ok
          </Button>
        </DialogActions>
      </Dialog>
     :null}




       <Dialog
        open={this.state.dialogue}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
       
        <DialogContent>
              <ReactLoading  type={"balls"} color="balck" />
        </DialogContent>
       
      </Dialog>
    </>

  );
  }
}



export default  withTranslation('common')(TableList);